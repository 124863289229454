<template>
  <div class="spinner">

    <SpinnerLoader />

    <div class="spinner__text">
      {{ descriptionView }}
    </div>
    <div class="redirect__text" v-if="$route.fullPath.includes('work')">
      <button class="btn btn-primary btn-bg-green" @click="$router.push({ name: 'showcase' })">На главную</button>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { verification } from '@/http/auth/authApi'
import { useStore } from 'vuex'
import SpinnerLoader from '@/components/views/Spinner/VSpinnerLoader'

const route = useRoute()
const router = useRouter()
const store = useStore()

const descriptionView = ref('')

onBeforeMount(async () => {
  if (route.fullPath.includes('work')) {
    descriptionView.value = 'На сайте ведутся технические работы'
  } else {
    descriptionView.value = 'Ожидаю ответ с сервера'
    setTimeout(async () => {
      if (route.query) {
        try {
          const data = await verification(route.query)
          await router.push({ name: 'login' })
          await store.dispatch('setAlert', {
            type: 'success',
            description: data.message
          })
        } catch (e) {
          await router.push({ name: 'login' })
          await store.dispatch('setAlert', {
            type: 'error',
            description: 'Верификация не пройдена'
          })
        }
      } else {
        await router.push({ name: 'login' })
      }
    }, 500)
  }
})
</script>

<style scoped lang="scss">
@import "~@/styles/style.scss";

.spinner {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: $color-yellow;

  &__text {
    margin-top: 20px;

    color: $color-primary;
    font-size: 18px;
  }
}

.redirect__text {
  margin-top: 20px;

  display: flex;
  justify-content: center;
}
</style>
